import * as ISOCountries from 'i18n-iso-countries';

// eslint-disable-next-line @typescript-eslint/no-var-requires
ISOCountries.registerLocale(require('i18n-iso-countries/langs/en.json'));

const defaultCountryNames = ISOCountries.getNames('en');

const extendedCountryNames = {
  US: ['United States of America', 'United States', 'USA'],
};

ISOCountries.registerLocale({
  locale: 'en',
  countries: Object.assign(defaultCountryNames, extendedCountryNames),
});

export function getAlpha2CodeFromName(countryName: string, language?: string) {
  language = language || 'en';
  return ISOCountries.getSimpleAlpha2Code(countryName, language);
}

export function getCountryIsoFromName(countryName: string, language?: string) {
  return getAlpha2CodeFromName(countryName, language);
}

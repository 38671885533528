import {
  ICart,
  ICartItem,
  IMerchStore,
} from '@merchstores/frontend/reduxTypes';
import {
  OrderType,
  CLOSE_DATE_PENDING,
} from '@merchstores/shared/components/MerchStore';
import { resolveMerchStoreDomain } from '@merchstores/frontend/components/Merchstore/Domain';
import { resolveMerchstoreUrl } from '@merchstores/frontend/components//Merchstore/Url';
import { resolveOriginalArtwork } from '@merchstores/shared/components/Artwork';
import {
  logoImageResizeByWidth,
  STORE_LOGO_SMALL_WIDTH,
} from '@merchstores/shared/components/Cloudinary';

import { chargeShippingUnlessExplicitFalse } from '@merchstores/shared/components/Shipping';
import { getProductVariantById } from '@merchstores/frontend/api/products/MerchStoreProducts';

import {
  ICartFields,
  ICustomAttribute,
  ILine,
  ILineItem,
  IMailingAddressInput,
  makeCartCreateMutation,
  makeCartCheckoutURLQuery,
} from '@merchstores/frontend/queries/storefront-checkout-2025-01';

import {
  createStorefrontApiClient,
  resolveApiUrl,
} from '@merchstores/frontend/api/shopify-storefront/StorefrontApiClient';

import { getCountryIsoFromName } from '@merchstores/frontend/components/ISOCountries';

const SHOPIFY_CHECKOUT_DOMAIN = process.env.REACT_APP_SHOPIFY_CHECKOUT_DOMAIN;

export interface ICheckoutFields {
  lineItems: Array<ILineItem>;
  customAttributes: Array<ICustomAttribute>;
  shippingAddress?: IMailingAddressInput;
}

export function customAttr(
  attrName: string,
  attrValue: string
): ICustomAttribute {
  return { key: attrName, value: attrValue };
}

export function buildLineItemAttributes(
  item: ICartItem
): Array<ICustomAttribute> {
  let attributes: Array<ICustomAttribute> = [];

  attributes = attributes.concat(
    customAttr('_Decoration type', item.decorationType || ''),
    customAttr('_Select decoration location', item.decorationLocation || ''),
    customAttr(
      '_Upload your logo (2MB max)',
      resolveOriginalArtwork(item.artwork || '')
    ),
    customAttr('_Logo Mockup Image', item.logoMockup || '')
  );

  return attributes.filter(
    (attr) => attr.value && typeof attr.value !== 'undefined'
  );
}

export function buildCartFields(
  cart: ICart,
  merchstore: IMerchStore
): ICartFields {
  const storeDomain = resolveMerchStoreDomain(merchstore);

  const storeUrl = resolveMerchstoreUrl(merchstore);

  const cartItems: Array<ICartItem> = cart.items;

  const baseLineItemAttributes: Array<ICustomAttribute> = [];

  const lineItems = cartItems.map((item) => {
    const lineItemAttributes = baseLineItemAttributes.concat(
      buildLineItemAttributes(item)
    );

    return {
      merchandiseId: item.id,
      quantity: item.quantity,
      attributes: lineItemAttributes,
    };
  });

  const checkoutCustomizationAttributes = [
    customAttr('_payer_type', merchstore.payerType || ''),
    customAttr('_order_type', OrderType.INDIVIDUAL),
    customAttr(
      '_ship_to_office',
      String(String(merchstore.shipToOffice) === 'true')
    ),
    customAttr(
      '_bagged_labeled',
      String(String(merchstore.baggedLabeled) === 'true')
    ),
    customAttr(
      '_charge_shipping',
      String(
        String(chargeShippingUnlessExplicitFalse(merchstore.chargeShipping)) ===
          'true'
      )
    ),
  ];

  if (lineItems.length) {
    lineItems[0].attributes = lineItems[0].attributes.concat(
      checkoutCustomizationAttributes
    );
  }

  if (lineItems.length > 1) {
    const lastElem = lineItems.length - 1;
    lineItems[lastElem].attributes = lineItems[lastElem].attributes.concat(
      checkoutCustomizationAttributes
    );
  }

  const cartAttributes: Array<ICustomAttribute> = [
    customAttr('subdomain', merchstore.subdomain),
    customAttr('merchstore_domain', storeDomain),
    customAttr('merchstore_url', storeUrl),
    customAttr('merchstore_code', merchstore.storeCode + ''),
    customAttr('payer_type', merchstore.payerType + ''),
    customAttr('order_type', OrderType.INDIVIDUAL),
    customAttr(
      'ship_to_office',
      String(String(merchstore.shipToOffice) === 'true')
    ),
    customAttr(
      'bagged_labeled',
      String(String(merchstore.baggedLabeled) === 'true')
    ),
    customAttr('close_date', CLOSE_DATE_PENDING),
    customAttr(
      'merchstore_logo',
      String(
        logoImageResizeByWidth(
          merchstore.storeLogo || '',
          STORE_LOGO_SMALL_WIDTH
        )
      )
    ),
    customAttr(
      'charge_shipping',
      String(
        String(chargeShippingUnlessExplicitFalse(merchstore.chargeShipping)) ===
          'true'
      )
    ),
  ].filter((attr) => attr.value && typeof attr.value !== 'undefined');

  if (cart?.member?.email) {
    cartAttributes.push(customAttr('member_email', cart.member.email));
  }

  const cartFields: ICartFields = {
    lines: lineItems,
    attributes: cartAttributes,
  };

  if (cart?.member?.email) {
    cartFields.buyerIdentity = {
      email: cart.member.email,
    };
  }
  if (merchstore.shipToOffice && merchstore.officeAddress) {
    cartFields.delivery = {
      addresses: [
        {
          address: {
            deliveryAddress: {
              company: merchstore.officeAddress.company,
              firstName: merchstore.officeAddress.firstName,
              lastName: merchstore.officeAddress.lastName,
              address1: merchstore.officeAddress.address1,
              address2: merchstore.officeAddress.address2,
              provinceCode: merchstore.officeAddress.province,
              zip: merchstore.officeAddress.zip,
              city: merchstore.officeAddress.city,
              countryCode: getCountryIsoFromName(
                merchstore.officeAddress.country
              ),
              phone: merchstore.officeAddress.phone || '',
            },
          },
        },
      ],
    };
  }
  return cartFields;
}

interface IErrorInvalidItem {
  code: string;
  variantId: string;
}

export async function extractCheckoutErrors(
  checkoutErrors: Array<{ code: string }>,
  cartFields: ICartFields
): Promise<Array<IErrorInvalidItem>> {
  const errors: Array<IErrorInvalidItem> = [];

  const invalidErrors = checkoutErrors.filter(
    (errorItem) => errorItem.code === 'INVALID'
  );

  if (!invalidErrors.length) {
    return errors;
  }

  const invalidItemErrorDetails = await Promise.all(
    cartFields.lines.map((lineItem) => {
      const errorCode = 'INVALID';

      const checkErrorPromise = getProductVariantById(lineItem.merchandiseId)
        .then((/*variantData*/) => {
          // ok! variant exists.
          return null;
        })
        .catch((variantError) => {
          console.error('getProductVariantById', variantError);
          return {
            code: errorCode,
            variantId: lineItem.merchandiseId,
          };
        });

      return checkErrorPromise as Promise<IErrorInvalidItem | null>;
    })
  );

  invalidItemErrorDetails.filter(Boolean).forEach((errorItem) => {
    errorItem = errorItem as IErrorInvalidItem;
    errors.push({ code: errorItem.code, variantId: errorItem.variantId });
  });

  return errors;
}

export interface ICheckoutStatus {
  checkoutUrl: string;
  errors: Array<IErrorInvalidItem>;
}

export async function requestCheckoutUrl(
  cartFields: ICartFields
): Promise<ICheckoutStatus> {
  const baggedLabeledAttr = cartFields.attributes.find(
    (attr) => attr.key === 'bagged_labeled'
  );
  const baggedLabeled = baggedLabeledAttr
    ? baggedLabeledAttr.value === 'true'
    : false;
  if (baggedLabeled) {
    const staging =
      process.env.REACT_APP_SHOPIFY_MERCH_SERVICES_STOREFRONT_URL?.includes(
        'staging'
      );
    const handlingCharge = {} as ILine;
    handlingCharge.merchandiseId = `gid://shopify/ProductVariant/${
      staging ? '46032476078330' : '43549215391941'
    }`;
    handlingCharge.quantity = 1;
    handlingCharge.attributes = [];
    cartFields.lines.push(handlingCharge);
  }

  const storefrontClient = createStorefrontApiClient({
    endpointUrl: resolveApiUrl('2025-01'),
  });

  const cartResponse = await storefrontClient.post(
    '',
    makeCartCreateMutation(cartFields)
  );

  const { data: cartResponseData } = cartResponse;
  const { cartCreate: cartCreateData } = cartResponseData;

  // Shopify no longer returns an errors object. If the cart is empty and the userErrors object is empty, throw an error.
  if (!cartCreateData.cart && !cartCreateData.userErrors.length) {
    throw new Error(JSON.stringify('ERROR CREATING CART', cartCreateData));
  }

  const cartId = cartCreateData.cart?.id;

  if (cartResponseData.userErrors?.length) {
    throw new Error(JSON.stringify(cartResponseData.userErrors));
  }

  const createErrors = cartCreateData.userErrors;

  const cartUrlResponse = await storefrontClient.post(
    '',
    makeCartCheckoutURLQuery(cartId)
  );

  const { data: cartUrlResponseData } = cartUrlResponse;
  const { cart: cartCheckoutUrlData } = cartUrlResponseData;

  const cartCheckoutUrl = cartCheckoutUrlData?.checkoutUrl;

  const errors = await extractCheckoutErrors(createErrors, cartFields);

  let checkoutUrl = cartCheckoutUrl || '';

  if (SHOPIFY_CHECKOUT_DOMAIN) {
    checkoutUrl = checkoutUrl.replace(
      /https:\/\/[^.]+\.myshopify\.com\//,
      `https://${SHOPIFY_CHECKOUT_DOMAIN}/`
    );
  }

  async function getGtag(tagName: string) {
    return new Promise((resolve) => {
      gtag('get', 'G-2BZW509C6Q', tagName, resolve);
    });
  }

  // Append the Google Analytics client_id and session_id to the checkout URL to enable cross-domain tracking. Via @noerbot

  const clientId = await getGtag('client_id');

  if (clientId) {
    checkoutUrl += `&client_id=${clientId}`;
  }

  const sessionId = await getGtag('session_id');

  if (sessionId) {
    checkoutUrl += `&session_id=${sessionId}`;
  }

  return {
    checkoutUrl: checkoutUrl,
    errors: errors,
  };
}
